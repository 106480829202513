
<template>
    <div class="recharge">
      <van-nav-bar left-text="返回"  title="优惠券" left-arrow @click-left="onClickLeft" />
      <div class="recharge_main">
        <div class="recharge_main_top">
          <div class="recharge_main_input">
            <van-cell-group>
              <van-field v-model="coupon" label="优惠券" placeholder="请输入卡密" >
                <template #button>
                    <van-button  size="mini" type="info" style="width: 50px;" @click="getCouponInfo">查询</van-button>
                </template>
              </van-field>
            </van-cell-group>
          </div>
        </div>
      </div>
      <div class="coupon" v-show="couponShow">
        <p class="title">{{ flg?'不可用优惠券':'可用优惠券' }}<span>1张</span> </p>
       <div class="card">
        <div class="couponInfo">
            <div>
                <p class="name">{{couponData.goodsName }}</p>
                <p  class="time">有限期至{{couponData.endTime}}</p>
            </div>
            <div class="price">
            <div>
                <p  class="deductPrice">￥<span>{{ couponData.deductPrice }}</span></p>
                <p  class="thresholdPrice">满{{couponData.thresholdPrice}}可用</p>
            </div>
                <van-radio-group v-model="radio" :disabled="flg" @change="change">
                    <van-radio name="2" checked-color="#ee0a24"></van-radio>
                </van-radio-group>
            </div>
            </div>
            <van-divider />
            <p class="error">{{ flg?'本单不可用原因':'本单补贴以券面实际展示为准' }}</p>
            <p class="message" v-show="flg">{{message }}</p>
        </div>
      </div>
      <van-tabbar >
      <van-button round type="danger" size="large" @click="Submit">使用优惠券</van-button>
    </van-tabbar>
    </div>
  </template>
  <script>
  import { api_coupon,api_phone_token} from "@/api/api.js"
  import { getQueryVariable,timestampToTime} from "@/util/utils.js"
  export default {
    data () {
      return {
        coupon: '',
        radio:"",
        productCode:'',
        orderAmount: "",
        phoneNum:"",
        couponData:{},
        message:"",
        couponShow:false,
        flg:false
      }
    },
  
    created () {
        this.productCode = getQueryVariable("productCode")
        this.phoneNum = getQueryVariable("phone")
        this.orderAmount = getQueryVariable("orderAmount")
        console.log( this.productCode, this.orderAmount)
    },
    methods: {
        onClickLeft(){
            this.$router.go(-1)
        },
        getCouponInfo(){
            const phone = {
                phone: this.phoneNum
                }
        api_phone_token(phone).then(res => {
          if (res.data.code == 0) {
            sessionStorage.setItem('accessToken', 'yh' + ' ' + res.data.data.accessToken)
            const params={
                productCode:this.productCode,
                orderAmount:this.orderAmount,
                coupon:this.coupon,
            }
            api_coupon(params).then(res=>{
                if(res.data.code==0){
                    this.couponData=res.data.data
                    this.flg=false
                    this.couponShow=true
                    timestampToTime(this.couponData.endTime)
                }else {
                    this.couponData=res.data.data
                    this.$toast(res.data.message);
                    this.message=res.data.message
                    timestampToTime( this.couponData.endTime)
                    this.couponShow=true
                    this.flg=true
                }
            })
          }
        })
        },
        change(val){
         
        },
        Submit(){
            if(!this.flg||this.radio==2){
                sessionStorage.setItem('couponID', this.couponData.couponID)
                sessionStorage.setItem('deductPrice', this.couponData.deductPrice)
                this.$router.go(-1)
            }else{
                this.$toast('当前暂时可用优惠券');
            }
        },
    },
    computed: {
    }
  }
  </script>
  <style lang="scss" scoped>
  .recharge {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    .recharge_main {
      padding: 1em;
      .van-cell{
        align-items: center;
      }
    }
    .coupon{
        padding:0 1em 1em 1em;
        .title{
            color: #333;
            font-size: 1.5em;
            margin: 0.5em 0;
            font-weight: 500;
            span{
                font-size: 1em;
            }
        }
        .card{
            border-radius: 1em;
            padding: 1em;
            background-color: #ffffff;
            .couponInfo{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name{
                font-size :1.5em;
                color: #333;
                line-height: 1.5em;
            }
            .time{
                color: #a09e9e;
                font-size: 1.1em;
            }
            .price{
            display: flex;
            align-items: center;
            justify-content: space-between;
            }
            .deductPrice{
                color:red;
                span{
                    font-size: 2.5em;
                }
            }
            .thresholdPrice{
                color:red;
                font-size: 1.1em;
            }
            .van-radio-group{
                margin-left: 2em;
            }
        }
        .error{
            color:red;
            font-size: 1.1em;
            line-height: 1.5em;
        }
        .message{
            color: #a09e9e;
            font-size: 1.1em;
            }
        }
        
    }
  }
  .van-tabbar--fixed{
    bottom: 20px;
    
  }
  .van-tabbar{
    background: 0;
    justify-content: center
  }
  .van-button--large{
    width: 80%;
  }
  </style>
  
  